<template>
  <div class="worldof__cms-page collections-page">
    <div class="worldof__content">
      <div class="cms-content" v-append-html="category.filtered_content_ext" />
    </div>
  </div>
</template>

<script>
import CmsBlogPage from 'theme/components/WorldOf/CmsBlogPage'

export default {
  name: 'Collections',
  mixins: [CmsBlogPage],
  methods: {
    postClicked ($event) {
      // get the id from the classList of the clicked cta
      const idPostsToLoad = [...$event.currentTarget.classList].find(item => item.includes('id'))

      // get the posts gallery (hidden and included inside the pagebuilder) with the same id
      const galleryElement = document.querySelector(`.collections-gallery-posts.${idPostsToLoad}`)
      const postsElements = galleryElement.querySelectorAll('.pagebuilder-column')

      // iterate posts and create the post objects with the informations taken from the pagebuilder (PHIL-50 for building info)
      let posts = []
      postsElements.forEach((postElement, index) => {
        const postImage = postElement.querySelector('.gallery-post-image img')
        const postShortContent = postElement.querySelector('.gallery-post-short-content')
        const postTitle = postElement.querySelector('.gallery-post-title')
        const post = {
          first_image: postImage.src,
          short_content: postShortContent.textContent,
          post_id: (index + 1),
          title: postTitle.textContent
        }
        posts.push(post)
      })

      // commit these posts data into state and initialize PostsSlide.vue
      this.$store.commit('ui/setPostsSlide', {
        posts,
        totalPosts: posts.length,
        currentIndex: 0,
        showSlide: true,
        parentLoadMoreCallback: 'onClickCollectionsGallery',
        componentClass: 'collections'
      })
    }
  },
  mounted () {
    if (process.client) {
      const _this = this
      $('.collections-gallery-cta').on('click', (event) => {
        _this.postClicked(event)
      })
    }
  },
  destroyed () {
    if (process.client) {
      const _this = this
      $('.collections-gallery-cta').off('click', (event) => {
        _this.postClicked(event)
      })
    }
  }
}
</script>

<template>
  <div class="worldof__posts">
    <div class="worldof__header" ref="worldOfHeader">
      <categories-list :category="category" :useOptions="categoryOptions" />
    </div>
    <div class="worldof__content">
      <post-list :posts="posts" :category="category"></post-list>
      <div v-show="!postsLoading && posts.length <= 0">
        <div class="nothing-found">
          <h4 v-t="'worldof.nothingFound'"></h4>
        </div>
      </div>
    </div>
    <div v-if="showLoadMore" class="worldof__footer">
      <div class="worldof__footer__load-more">
        <button class="pagebuilder-button-link-primary" v-t="labelShowMore" @click="onClickLoadMore"/>
      </div>
    </div>
  </div>
</template>

<script>
import CategoriesList from 'theme/components/Blog/CategoriesList'
import PostList from 'theme/components/Blog/PostList'
import Config from 'theme/config'
import { EventBus } from '@/helpers'

export default {
  name: 'News',
  props: {
    category: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
      posts: [],
      tags: [],
      currentPage: 1,
      postsLoading: true,
      pageSize: Config.Theme.default.blogPostSize,
      totalCount: 0,
      slideShowCurrentIndex: 0,
      showSlide: false
    }
  },
  computed: {
    getPosts () {
      return this.$store.getters['blog/getPosts']
    },
    labelShowMore () {
      return this.$t('worldof.viewMore')
    },
    showLoadMore () {
      return this.posts.length < this.totalCount
    },
    isMobile () {
      return this.$store.state.ui.isMobile
    },
    allCategories () {
      return this.$store.state.category.flatted
    },
    categoryOptions () {
      const _this = this
      let match = _this.allCategories.find((cat) => cat.blog_category_id === _this.category.parent_category_id)
      if (match && match.childrenData.length) {
        // return match.childrenData.filter((cat) => cat.custom_layout === 'press-and-celeb')
        return match.childrenData
      } else {
        return []
      }
    }
  },
  methods: {
    onClickLoadMore () {
      this.currentPage += 1
      this.loadPosts(this.selectedTag, true)
    },
    async loadPosts (isLoadMore = false) {
      const _this = this
      /* reset pagination if a new tag is selected */
      // if (!isLoadMore) {
      //   this.currentPage = 1
      // }
      this.$store.commit('ui/showLoader')
      await this.$store.dispatch('blog/getPosts', {
        filter: {
          currentPage: this.currentPage,
          pageSize: Config.Theme.default.blogPostSize,
          filters: {
            category_id: {
              eq: this.category.category_id
            }
          }
        }
      })
        .then((res) => {
          if (res && res.data && res.data.blogPosts) {
            if (this.showLoadMore) {
              _this.posts = _this.posts.concat(res.data.blogPosts.items)
            } else {
              _this.posts = res.data.blogPosts.items
            }
            _this.totalCount = res.data.blogPosts.total_count
          }
        })
        .finally(() => {
          _this.$store.commit('ui/hideLoader')
          _this.postsLoading = false
        })
    },
    postClicked ($event) {
      // load in posts with postIndex
      this.$store.commit('ui/setPostsSlide', {
        posts: this.posts,
        totalPosts: this.posts.length,
        currentIndex: $event.listKey,
        showSlide: true,
        canLoadMore: this.showLoadMore,
        parentLoadMoreCallback: 'onClickLoadMore',
        componentClass: 'world-of'
      })
    },
    indexChanged (index) {
      this.slideShowCurrentIndex = index
    },
    closeSlide () {
      this.showSlide = false
    }
  },
  mounted () {
    if (process.client) {
      const _this = this
      this.loadPosts()
      $('.app-view').addClass('blog-post-page')
      EventBus.$on('worldof:postClicked', function (event) {
        _this.postClicked(event)
      })
    }
  },
  destroyed () {
    $('.app-view').removeClass('blog-post-page')
  },
  components: {
    PostList,
    CategoriesList
  }
}
</script>

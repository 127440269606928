<template>
  <div class="worldof__cms-page inside-page">
    <div class="worldof__header">
      <categories-list :category="category" />
    </div>
    <div class="worldof__content">
      <div class="cms-content" v-append-html="category.filtered_content_ext" />
    </div>
  </div>
</template>

<script>
import { localizedByNameCategories } from '@/filters/routers'
import find from 'lodash/find'
import CmsBlogPage from 'theme/components/WorldOf/CmsBlogPage'

export default {
  name: 'Inside',
  mixins: [CmsBlogPage],
  beforeMount () {
    let blogCategory = this.$store.state.category.flatted
    let match = find(blogCategory, { blog_category_id: this.category.category_id })
    if (match && match.childrenData.length) {
      let currentChild = match.childrenData[0]
      return this.$router.push({ path: localizedByNameCategories('listing', currentChild.url_path, null, this.$store, this.$router) })
    } else {
      return true
    }
  }
}
</script>

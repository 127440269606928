<template>
  <div class="worldof__cms-page about-page">
    <div class="worldof__content">
      <div class="cms-content" v-append-html="category.filtered_content_ext" />
    </div>
  </div>
</template>

<script>
import CmsBlogPage from 'theme/components/WorldOf/CmsBlogPage'

export default {
  name: 'About',
  mixins: [CmsBlogPage]
}
</script>

import { render, staticRenderFns } from "./PostTile.vue?vue&type=template&id=b49dc760&"
import script from "./PostTile.vue?vue&type=script&lang=js&"
export * from "./PostTile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "PostTile.vue"
export default component.exports
<template>
  <div class="categories-list">
    <ul class="nav">
      <li v-for="cat in list" :key="cat.id">
        <router-link :to="'listing' | localizedByNameCategories(cat.url_path, null, $store, $router)" class="bcm-link bcm-link-primary" :class="{'bcm-link-underline-primary': category.category_id === cat.blog_category_id }">
          {{cat.name}}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import find from 'lodash/find'
import uniqBy from 'lodash/uniqBy'

export default {
  name: 'CategoriesList',
  props: {
    category: {
      type: Object,
      required: true
    },
    useOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    blogCategories () {
      return this.$store.state.category.flatted
    },
    categoryList () {
      if (this.useOptions && this.useOptions.length) {
        return this.useOptions
      } else {
        // load categories list
        // first check for subchild
        // etherwise load by parent
        const _this = this
        const categoryLoop = (categoryId) => {
          let match = find(_this.blogCategories, { blog_category_id: categoryId })
          if (match && match.childrenData.length === 0) {
            return categoryLoop(match.blog_category_parent_id)
          } else {
            return match.childrenData
          }
        }
        return categoryLoop(_this.category.category_id)
      }
    },
    list () {
      return uniqBy(this.categoryList, 'name')
    }
  }
}
</script>

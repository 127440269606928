import { render, staticRenderFns } from "./WorldOf.vue?vue&type=template&id=28e83072&"
import script from "./WorldOf.vue?vue&type=script&lang=js&"
export * from "./WorldOf.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "WorldOf.vue"
export default component.exports
<template>
  <div class="worldof__cms-page">
    <div class="worldof__header">
      <categories-list :category="category" />
    </div>
    <div class="worldof__content">
      <div class="cms-content" v-append-html="category.filtered_content_ext" />
    </div>
  </div>
</template>

<script>
import CategoriesList from 'theme/components/Blog/CategoriesList'

export default {
  name: 'CmsBlogPage',
  props: {
    category: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  components: {
    CategoriesList
  }
}
</script>
